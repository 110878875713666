import { motion } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Header from "./header"
import "./layout.scss"
import { Helmet } from "react-helmet"

const Layout = (props) => {
    const { pageTitle, children, expanded = false, description = "Max Tyler's personal website" } = props
    const data = useStaticQuery(graphql`
query {
site {
siteMetadata {
title
}
}
}`)
    const title = (pageTitle === null ?
        data.site.siteMetadata.title : `${pageTitle} | ${data.site.siteMetadata.title}`)
    return (<>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta charset="UTF-8" />
            <meta name="google-site-verification" content="2ptvA69xXIdf71SiiOcx1GixXZt5s-EdrkIwtQMPje0" />
        </Helmet>
        <div className="container">
            <div className="content">
                <Header expanded={expanded} />
                <motion.div className="animated-content">
                    {children}
                </motion.div>
            </div>
            <div>
                <hr />
                <footer className="footer">
                    <div className="footer-content">
                        <a href="https://github.com/maxastyler">My Github</a>
                    </div>
                </footer>
            </div>
        </div>
    </>
    )
}

export default Layout
