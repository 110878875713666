import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import "./layout.scss"

const Nav = () => {
    return (<motion.nav>
        <motion.ul className="nav-links" layoutId="nav-links">
            <motion.li className="nav-link-item"><Link to="/" className="nav-link-text">Home</Link></motion.li>
            <motion.li className="nav-link-item"><Link to="/blog" className="nav-link-text">Blog</Link></motion.li>
            <motion.li className="nav-link-item"><Link to="/tags" className="nav-link-text">Tags</Link></motion.li>
        </motion.ul>
    </motion.nav>)
}

const ImageDiv = ({ expanded }) => {
    return (<motion.div layoutId="image-container">
        <StaticImage src="../images/main.jpg" alt="image of me" className="imageOfMe" style={{ maxHeight: (expanded ? 300 : 100), maxWidth: (expanded ? 300 : 100), objectFit: "scale-down", borderRadius: (expanded ? "20%" : "100%") }} />
    </motion.div>)
}

const Header = ({ expanded = false }) => {
    return (<motion.div className="header-container"
        style={{
            display: "flex",
            flexDirection: (expanded ? "column" : "row"),
            justifyContent: "center",
            alignItems: "center",
        }} animate={{ scale: 1, opacity: 1 }} layoutId="header">
        <Nav layoutId="navigation" />
        <ImageDiv expanded={expanded} />
    </motion.div>
    )
}

export default Header
